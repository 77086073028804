<template>
  <CContainer class="p-0">
    <CHeader fixed id="dateselector-header" class="vue-fixed-header" style="z-index:1028">
      <div class="custom">
        <CButtonGroup>
          <CDropdown
            :toggler-text="chart.model.selected_default_date_range.label"
            :v-model="chart.model.selected_default_date_range"
            :placeholder="$t('ANALYTICS.DATE')"
            class="m-2"
            color="darkborder"
          >
            <CIcon name="cil-calender" />
            <div
              v-for="(option, index) in chart.model.dayOptions"
              :key="index"
              v-on:click="selectDefaultDateRange(option)"
            >
              <CDropdownItem :value="option.days">
                {{
                option.label
                }}
              </CDropdownItem>
            </div>
          </CDropdown>
          <div v-show="showCalendar">
            <date-picker
              size="sm"
              class="m-2 info"
              v-model="chart.model.dates"
              type="date"
              range
              :disabled-date="disableFutureDates"
              :placeholder="$t('ANALYTICS.DATE')"
              @change="selectDateRange"
            ></date-picker>
          </div>
          <CDropdown
            :toggler-text="chart.model.time_period.label"
            :v-model="chart.model.time_period.label"
            class="m-2"
            color="darkborder"
          >
            <div
              v-for="(option, index) in chart.model.chartDisplayOptions"
              :key="index"
              v-on:click="selectGranularity(option)"
            >
              <CDropdownItem :value="option">
                {{
                option.label
                }}
              </CDropdownItem>
            </div>
          </CDropdown>
        </CButtonGroup>
      </div>
    </CHeader>
    <CRow class="custom">
      <CCol sm="12">
        <CCard>
          <CCardHeader class="p-3 px-4">
            <CRow>
              <CCol sm="9">
                <h5 id="traffic" class="card-title mb-0">{{ $t("ANALYTICS.REVENUE") }}</h5>
              </CCol>
              <CCol sm="3">
                <span
                  v-if="chart.model.selected_default_date_range.days > 0"
                  class="timespan mb-0"
                >Last {{ chart.model.selected_default_date_range.days }} Days</span>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class="p-0">
            <CContainer>
              <CRow class="singleChartRow">
                <CCol sm="12">
                  <TransactionsRevenueChart
                    :v-show="showTransRevChart"
                    :name="$t('ANALYTICS.CHARTS.TRANSACTIONS_REVENUE')"
                    :Max="getChartDetails('TransRevChart', 'max')"
                    :DataSets="getChartDetails('TransRevChart', 'dataset')"
                    :Labels="getChartDetails('TransRevChart', 'label')"
                    class="analyticsChart"
                  />
                </CCol>
              </CRow>
            </CContainer>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow class="custom">
      <CCol sm="6">
        <CCard>
          <CCardHeader class="p-3 px-4">
            <CRow>
              <CCol sm="8">
                <h5 id="traffic" class="card-title mb-0">{{ $t("ANALYTICS.TRANSACTIONS_NUMBER") }}</h5>
              </CCol>
              <CCol sm="4">
                <span
                  v-if="chart.model.selected_default_date_range.days > 0"
                  class="timespan mb-0"
                >Last {{ chart.model.selected_default_date_range.days }} Days</span>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class="p-0">
            <CContainer>
              <CRow class="chartRow">
                  <TransactionsCountChart
                    :v-show="showTransCountChart"
                    :name="$t('ANALYTICS.CHARTS.TRANSACTIONS_COUNT')"
                    :Max="getChartDetails('TransCountChart', 'max')"
                    :DataSets="getChartDetails('TransCountChart', 'dataset')"
                    :Labels="getChartDetails('TransCountChart', 'label')"
                    class="analyticsChart"
                  />
              </CRow>
            </CContainer>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="6">
        <CCard>
          <CCardHeader class="p-3 px-4">
            <CRow>
              <CCol sm="8">
                <h5 id="traffic" class="card-title mb-0">{{ $t("ANALYTICS.TRANSACTIONS_AVERAGE") }}</h5>
              </CCol>
              <CCol sm="4">
                <span
                  v-if="chart.model.selected_default_date_range.days > 0"
                  class="timespan mb-0"
                >Last {{ chart.model.selected_default_date_range.days }} Days</span>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class="p-0">
            <CContainer>
              <CRow class="chartRow">
                  <TransactionsAverageChart
                    :v-show="showTransAvgChart"
                    :name="$t('ANALYTICS.CHARTS.TRANSACTIONS_AVERAGE')"
                    :Max="getChartDetails('TransAvgChart', 'max')"
                    :DataSets="getChartDetails('TransAvgChart', 'dataset')"
                    :Labels="getChartDetails('TransAvgChart', 'label')"
                    class="analyticsChart"
                  />
              </CRow>
            </CContainer>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import DatePicker from "vue2-datepicker";

import TransactionsRevenueChart from "./charts/TransactionsRevenueChart";
import TransactionsCountChart from "./charts/TransactionsCountChart";
import TransactionsAverageChart from "./charts/TransactionsAverageChart";
import ChartBuilder from "./../../models/builder/chartBuilder.js";
import { listTransactionsData } from "@/api/analytics.api.js";
import "vue2-datepicker/index.css";

export default {
  name: "Transactions",
  components: {
    DatePicker,
    TransactionsRevenueChart,
    TransactionsCountChart,
    TransactionsAverageChart
  },
  data() {
    return {
      showCalendar: false,
      chart: {},
      transactionsRevenue: this.$t("ANALYTICS.CHARTS.TRANSACTIONS_REVENUE"),
      transactionsCount: this.$t("ANALYTICS.CHARTS.TRANSACTIONS_COUNT"),
      transactionsAverage: this.$t("ANALYTICS.CHARTS.TRANSACTIONS_AVERAGE"),
      showTransRevChart: false,
      showTransCountChart: false,
      showTransAvgChart: false
    };
  },
  methods: {
    disableFutureDates(date) {
      return this.chart.futureDates(date);
    },
    selectDefaultDateRange(val) {
      this.chart.selectDefaultDateRange(val);
      if (val.days > 0) {
        this.showCalendar = false;
        this._refreshCharts();
      } else {
        this.showCalendar = true;
      }
    },
    selectGranularity(val) {
      if (this.chart.model.time_period.value != val.value) {
        this.chart.model.time_period = val;
        this._refreshCharts();
      }
    },
    selectDateRange() {
      if (
        this.ifValid(this.chart.model.dates[0]) &&
        this.ifValid(this.chart.model.dates[1])
      ) {
        this._refreshCharts();
      }
    },
    _refreshCharts() {
      this.chart.model.allCharts = [];
      // add call to invoke all api
      this.getTransactionsChart();
    },
    getTransactionsChart() {
      const params = this.defaultParams();
      listTransactionsData(params)
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            try {
              this.formatDataforGraph(response.data.data);
            } catch (error) {
              console.error(error);
            }
          }
        })
        .catch(this.handleAPIError);
    },
    defaultParams() {
      const params = new URLSearchParams();
      params.append(
        "from",
        this.moment(this.chart.model.dates[0])
          .format("YYYY-MM-DD")
          .toString()
      );
      params.append(
        "to",
        this.moment(this.chart.model.dates[1])
          .format("YYYY-MM-DD")
          .toString()
      );
      params.append("time_period", this.chart.model.time_period.value);
      return params;
    },
    initializeChart() {
      return JSON.parse(JSON.stringify(this.chart));
    },
    formatDataforGraph(data) {
      if (data == null) return;
      this.showTransRevChart = false;
      this.showTransCountChart = false;
      this.showTransAvgChart = false;

      let chartTransRev = this.initializeChart();
      let chartTransCount = this.initializeChart();
      let chartTransAvg = this.initializeChart();

      chartTransRev.id = "TransRevChart";
      chartTransCount.id = "TransCountChart";
      chartTransAvg.id = "TransAvgChart";

      chartTransRev.dataset = [];
      chartTransCount.dataset = [];
      chartTransAvg.dataset = [];

      for (let i = 0; i < data.length; i++) {
        const element = data[i];

        chartTransRev.dataset.push(element.revenue);
        chartTransCount.dataset.push(element.count);
        chartTransAvg.dataset.push(element.average_value);

        chartTransRev.labels.push(
          this.getLabel(element.from, element.to, this.chart.model.time_period)
        );
      }

      chartTransCount.labels = chartTransRev.labels;
      chartTransAvg.labels = chartTransRev.labels;

      this.setChartMax(chartTransRev);
      this.setChartMax(chartTransCount);
      this.setChartMax(chartTransAvg);

      this.chart.model.allCharts.push(chartTransRev);
      this.chart.model.allCharts.push(chartTransCount);
      this.chart.model.allCharts.push(chartTransAvg);

      this.showTransRevChart = true;
      this.showTransCountChart = true;
      this.showTransAvgChart = true;
    },
    setChartMax(chart) {
      if (Math.max(...chart.dataset) > 10) {
        chart.max = Math.max(...chart.dataset);
      } else {
        chart.max = 10;
      }
    },
    getLabel(from, to, time_period) {
      return this.chart.getLabels(from, to, time_period);
    },
    getChartDetails(id, key) {
      return this.chart.getDetails(id, key);
    },
    initialize() {
      this.chart = new ChartBuilder().build();
      this.chart.initialize();
      this._refreshCharts();
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style scoped>
.btn-secondary {
  width: 150px;
}
.mx-datepicker-range {
  width: 250px;
}
.hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.vr {
  margin-left: 1rem;
  margin-right: 1rem;
  border: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.settlements {
  border-color: lightskyblue;
}
.wrapper {
  display: flex;
  justify-content: space-between;
}
.filter {
  background: none;
}
.btn-timefilter {
  background-color: grey;
  color: black;
  border-color: black;
}
.card-title {
  font-family: "Apercu";
  float: left;
}
.percentage-legend {
  font-size: 22px;
  margin-bottom: 5px;
  margin-left: 22px;
  float: left;
  width: 100%;
}
.badge-legend {
  display: block;
  min-width: 20px;
  width: 20px;
  margin-right: 5px;
  height: 10px;
  border-radius: 2px;
  float: left;
  margin-top: 5px;
}
h3,
.h3 {
  font-family: "Apercu";
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 1em;
}
.custom {
  padding-top: 10px;
}
.dateselector-header {
  background: none;
}
.vue-fixed-header--isFixed {
  position: fixed;
}

.timespan {
  font-size: 12px;
  line-height: 24px;
  text-align: right;
  float: right;
  color: #818386;
}

.singleChartRow {
  padding: 24px 10px 30px 20px;
}

.p-0 {
  padding: 0 !important;
  padding-top: 10px !important;
}

.analyticsChart {
  height: 200px;
  width: 100%;
  margin-top: 10px;
}
.chartRow {
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
}
</style>