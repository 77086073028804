<template>
  <CContainer class="p-0">
    <CHeader fixed id="dateselector-header" class="vue-fixed-header" style="z-index:1028">
      <div class="custom">
        <CButtonGroup>
          <CDropdown
            :toggler-text="chart.model.selected_default_date_range.label"
            :v-model="chart.model.selected_default_date_range"
            :placeholder="$t('ANALYTICS.DATE')"
            class="m-2"
            color="darkborder"
          >
            <CIcon name="cil-calender" />
            <div
              v-for="(option, index) in chart.model.dayOptions"
              :key="index"
              v-on:click="selectDefaultDateRange(option)"
            >
              <CDropdownItem :value="option.days">
                {{
                option.label
                }}
              </CDropdownItem>
            </div>
          </CDropdown>
          <div v-show="showCalendar">
            <date-picker
              size="sm"
              class="m-2 info"
              v-model="chart.model.dates"
              type="date"
              :disabled-date="getDisabledDates"
              range
              :placeholder="$t('ANALYTICS.DATE')"
              @change="selectDateRange"
            ></date-picker>
          </div>
          <CDropdown
            :toggler-text="chart.model.time_period.label"
            :v-model="chart.model.time_period.label"
            class="m-2"
            color="darkborder"
          >
            <div
              v-for="(option, index) in chart.model.chartDisplayOptions"
              :key="index"
              v-on:click="selectGranularity(option)"
            >
              <CDropdownItem :value="option">
                {{
                option.label
                }}
              </CDropdownItem>
            </div>
          </CDropdown>
        </CButtonGroup>
      </div>
    </CHeader>
    <CRow class="custom">
      <CCol sm="6">
        <CCard>
          <CCardHeader class="p-3 px-4">
            <CRow>
              <CCol sm="8">
                <h5 id="traffic" class="card-title mb-0">{{ $t("ANALYTICS.CONVER_RATE") }}</h5>
              </CCol>
              <CCol sm="4">
                <span
                  v-if="chart.model.selected_default_date_range.days > 0"
                  class="timespan mb-0"
                >{{$t('ANALYTICS.LAST')}} {{ chart.model.selected_default_date_range.days }} {{$t('ANALYTICS.DAYS')}}</span>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class="p-0" v-if="showOverAllConRateChart">
            <CContainer>
              <CRow>
                <OverAllConversionRate
                  :v-show="showOverAllConRateChart"
                  :Max="getChartDetails(OverAllConversionRateChartID, 'max')"
                  :DataSets="getChartDetails(OverAllConversionRateChartID, 'dataset')"
                  :Labels="getChartDetails(OverAllConversionRateChartID, 'label')"
                  class="analyticsChart"
                />
              </CRow>
            </CContainer>
          </CCardBody>
          <CCardBody class="p-0" v-else>
            <CContainer class="analyticsContainer">
              <img
                src="./../../../src/assets/img/notFound.svg"
                class="center"
                alt="default.svg"
                height="200px"
                width="100%"
              />
            </CContainer>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="6">
        <CCard>
          <CCardHeader class="p-3 px-4">
            <CRow>
              <CCol sm="8">
                <h5 id="traffic" class="card-title mb-0">{{ $t("ANALYTICS.CONVER_DROP_OFF") }}</h5>
              </CCol>
              <CCol sm="4">
                <span
                  v-if="chart.model.selected_default_date_range.days > 0"
                  class="timespan mb-0"
                >{{$t('ANALYTICS.LAST')}} {{ chart.model.selected_default_date_range.days }} {{$t('ANALYTICS.DAYS')}}</span>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class="p-0" v-if="showPageToPageChart">
            <CContainer>
              <CRow>
                <PageToPageConversionRate
                  :v-show="showPageToPageChart"
                  :Max="getChartDetails(PageToPageConversionRateChartID, 'max')"
                  :DataSets="getChartDetails(PageToPageConversionRateChartID, 'dataset')"
                  :Labels="getChartDetails(PageToPageConversionRateChartID, 'label')"
                  :Total="getChartDetails(PageToPageConversionRateChartID, 'total')"
                  class="analyticsChart"
                />
              </CRow>
            </CContainer>
          </CCardBody>
          <CCardBody class="p-0" v-else>
            <CContainer class="analyticsContainer">
              <img
                src="./../../../src/assets/img/notFound.svg"
                class="center"
                alt="default.svg"
                height="200px"
                width="100%"
              />
            </CContainer>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow class="custom">
      <CCol>
        <CCard>
          <CCardHeader class="p-3 px-4">
            <CRow>
              <CCol class="pills-wrapper">
                <b-nav pills>
                  <b-nav-item
                    @click="selectPage('bca-va')"
                    :active="page === 'bca-va'"
                  >{{ $t('ANALYTICS.PAGES.BCAVA') }}</b-nav-item>
                  <b-nav-item
                    @click="selectPage('mandiri-va')"
                    :active="page === 'mandiri-va'"
                  >{{ $t('ANALYTICS.PAGES.MANDIRIVA') }}</b-nav-item>
                   <b-nav-item
                    @click="selectPage('bri-va')"
                    :active="page === 'bri-va'"
                  >{{ $t('ANALYTICS.PAGES.BRIVA') }}</b-nav-item>
                   <b-nav-item
                    @click="selectPage('bni-va')"
                    :active="page === 'bni-va'"
                  >{{ $t('ANALYTICS.PAGES.BNIVA') }}</b-nav-item>
                   <b-nav-item
                    @click="selectPage('permata-va')"
                    :active="page === 'permata-va'"
                  >{{ $t('ANALYTICS.PAGES.PERMATAVA') }}</b-nav-item>
                   <b-nav-item
                    @click="selectPage('other-bank-va')"
                    :active="page === 'other-bank-va'"
                  >{{ $t('ANALYTICS.PAGES.OTHERBANKVA') }}</b-nav-item>
                  <b-nav-item
                    @click="selectPage('card-payment')"
                    :active="page === 'card-payment'"
                  >{{ $t('ANALYTICS.PAGES.CARD') }}</b-nav-item>
                  <b-nav-item
                    @click="selectPage('linkaja')"
                    :active="page === 'linkaja'"
                  >{{ $t('ANALYTICS.PAGES.LINKAJA') }}</b-nav-item>
                  <b-nav-item
                    @click="selectPage('ovo')"
                    :active="page === 'ovo'"
                  >{{ $t('ANALYTICS.PAGES.OVO') }}</b-nav-item>
                  <b-nav-item
                    @click="selectPage('dana')"
                    :active="page === 'dana'"
                  >{{ $t('ANALYTICS.PAGES.DANA') }}</b-nav-item>
                </b-nav>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class="p-0" v-if="showPageSpecificChart">
            <CContainer>
              <CCardHeader class="p-3 px-4">
                <CRow>
                  <CCol sm="8">
                    <h5 id="traffic" class="card-title mb-0">{{ $t("ANALYTICS.CONVERSION_RATE") }}</h5>
                  </CCol>
                  <CCol sm="4">
                    <span
                      v-if="chart.model.selected_default_date_range.days > 0"
                      class="timespan mb-0"
                    >{{$t('ANALYTICS.LAST')}} {{ chart.model.selected_default_date_range.days }} {{$t('ANALYTICS.DAYS')}}</span>
                  </CCol>
                </CRow>
              </CCardHeader>
              <PageSpecificConversionRate
                :v-show="showPageSpecificChart"
                :Max="getChartDetails(PageSpecificConversionRateChartID, 'max')"
                :DataSets="getChartDetails(PageSpecificConversionRateChartID, 'dataset')"
                :Labels="getChartDetails(PageSpecificConversionRateChartID, 'label')"
                class="analyticsChart"
              />
            </CContainer>
          </CCardBody>
          <CCardBody class="p-0" v-else>
            <CContainer class="analyticsContainer">
              <CCardHeader class="p-3 px-4">
                <CRow>
                  <CCol sm="8">
                    <h5 id="traffic" class="card-title mb-0">{{ $t("ANALYTICS.CONVERSION_RATE") }}</h5>
                  </CCol>
                  <CCol sm="4">
                    <span
                      v-if="chart.model.selected_default_date_range.days > 0"
                      class="timespan mb-0"
                    >{{$t('ANALYTICS.LAST')}} {{ chart.model.selected_default_date_range.days }} {{$t('ANALYTICS.DAYS')}}</span>
                  </CCol>
                </CRow>
              </CCardHeader>
              <img
                src="./../../../src/assets/img/notFound.svg"
                class="center"
                alt="default.svg"
                height="150px"
                width="100%"
              />
            </CContainer>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import DatePicker from "vue2-datepicker";
import OverAllConversionRate from "./charts/OverAllConversionRate";
import PageToPageConversionRate from "./charts/PageToPageConversionRate";
import PageSpecificConversionRate from "./charts/PageSpecificConversionRateChart";
import ChartBuilder from "./../../models/builder/chartBuilder.js";
import { getConversionRates } from "@/api/analytics.api.js";
import "vue2-datepicker/index.css";

export default {
  name: "ConversionRate",
  components: {
    DatePicker,
    OverAllConversionRate,
    PageToPageConversionRate,
    PageSpecificConversionRate
  },
  data() {
    return {
      page: "bca-va",
      OverAllConversionRateChartID: "OVCR",
      PageToPageConversionRateChartID: "P2PCR",
      PageSpecificConversionRateChartID: "PSCR",
      viewOptionsPage2Page: "byPage",
      viewOptionsOverAll: "total",
      chart: {},
      showOverAllConRateChart: false,
      showPageToPageChart: false,
      showPageSpecificChart: false,
      showCalendar: false
    };
  },
  methods: {
    getDisabledDates(date) {
      return this.chart.futureDates(date);
    },
    selectDefaultDateRange(val) {
      this.chart.selectDefaultDateRange(val);
      if (val.days > 0) {
        this.showCalendar = false;
        this._refreshCharts();
      } else {
        this.showCalendar = true;
      }
    },
    selectGranularity(val) {
      if (this.chart.model.time_period.value != val.value) {
        this.chart.model.time_period = val;
        this._refreshCharts();
      }
    },
    selectDateRange() {
      if (
        this.ifValid(this.chart.model.dates[0]) &&
        this.ifValid(this.chart.model.dates[1])
      ) {
        this._refreshCharts();
      }
    },
    _refreshCharts() {
      this.chart.model.allCharts = [];
      this.getCoversionRateChart(this.OverAllConversionRateChartID, this.page);
      this.getCoversionRateChart(
        this.PageToPageConversionRateChartID,
        this.page
      );
      this.getCoversionRateChart(
        this.PageSpecificConversionRateChartID,
        this.page
      );
    },
    getViewType(id, page) {
      switch (id) {
        case this.OverAllConversionRateChartID:
          return this.viewOptionsOverAll;
        case this.PageToPageConversionRateChartID:
          return this.viewOptionsPage2Page;
        case this.PageSpecificConversionRateChartID:
          return page;
      }
    },
    getCoversionRateChart(id, page) {
      const params = this.defaultParams(id, page);
      getConversionRates(params)
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            try {
              this.formatDataforGraph(response.data.data, id);
            } catch (error) {
              console.error(error);
            }
          }
        })
        .catch(e => {
          this.toggleCharts(id, false);
          this.handleAPIError(e);
        });
    },
    defaultParams(id, page) {
      const params = new URLSearchParams();
      params.append("from", this.moment(this.chart.model.dates[0]).format());
      params.append("to", this.moment(this.chart.model.dates[1]).format());
      params.append("unit", this.chart.model.time_period.value);
      params.append("view", this.getViewType(id, page));
      return params;
    },
    initializeChart() {
      return JSON.parse(JSON.stringify(this.chart));
    },
    toggleCharts(id, value) {
      switch (id) {
        case this.OverAllConversionRateChartID:
          this.showOverAllConRateChart = value;
          break;
        case this.PageToPageConversionRateChartID:
          this.showPageToPageChart = value;
          break;
        case this.PageSpecificConversionRateChartID:
          this.showPageSpecificChart = value;
          break;
      }
    },
    formatDataforGraph(data, id) {
      if (data == null) return;

      this.toggleCharts(id, false);

      let chart = this.initializeChart();

      chart.id = id;
      chart.dataset = [];
      chart.total = 0;
      data.data.forEach(element => {
        if (id == this.PageToPageConversionRateChartID) {
          chart.dataset.push(element.conversion_rate);
          chart.total = Math.max(...chart.dataset);
        } else {
          chart.dataset.push(element.conversion_rate);
        }
        chart.labels.push(
          this.getDomainSpecificLabel(
            id,
            element.domain,
            this.chart.model.time_period
          )
        );
      });
      this.setChartMax(chart);
      this.chart.model.allCharts.push(chart);
      this.toggleCharts(id, true);
    },
    setChartMax(chart) {
      chart.max = 100; //charts show 0 to 100%
    },
    getDomainSpecificLabel(id, domain, time_period) {
      switch (id) {
        case this.PageToPageConversionRateChartID:
          let label = [];
          label.push(this.getPageName(domain.page));
          label.push(domain.visits);
          return label;
        default:
          return this.getLabel(domain.from, domain.to, time_period);
      }
    },
    getPageName(page) {
      switch (page) {
        case "checkout_summary_page":
          return this.$t("ANALYTICS.PAGES.ORDER_SUMMARY");
        case "select_payment_page":
          return this.$t("ANALYTICS.PAGES.SELECT_PAYMENT");
        case "payment_page":
          return this.$t("ANALYTICS.PAGES.PAYMENT_PAGE");
        case "transaction_success_page":
          return this.$t("ANALYTICS.PAGES.SUCCESS_PAYMENT");
        default:
          break;
      }
    },
    getLabel(from, to, time_period) {
      return this.chart.getLabels(from, to, time_period);
    },
    getChartDetails(id, key) {
      return this.chart.getDetails(id, key);
    },
    selectPage(page) {
      this.getCoversionRateChart(this.PageSpecificConversionRateChartID, page);
      this.page = page;
    },
    initialize() {
      this.chart = new ChartBuilder().build();
      this.chart.initialize();
      this._refreshCharts();
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style scoped>
.mx-datepicker-range {
  width: 250px;
}
.wrapper {
  display: flex;
  justify-content: space-between;
}
.btn-timefilter {
  background-color: grey;
  color: black;
  border-color: black;
}
.card-title {
  font-family: "Apercu";
  float: left;
}
.custom {
  padding-top: 10px;
}
.dateselector-header {
  background: none;
}
.vue-fixed-header--isFixed {
  position: fixed;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.timespan {
  font-size: 12px;
  line-height: 24px;
  text-align: right;
  float: right;
  color: #818386;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: white;
  background: #418af8;
  height: 32px;
  text-decoration: none;
}

.nav-link {
  text-decoration: none;
}
.p-0 {
  padding: 0 !important;
  padding-top: 10px !important;
}
.analyticsChart {
  height: 200px;
  width: 100%;
  margin-top: 10px;
}
.analyticsContainer {
  height: 230px;
  width: 100%;
}
</style>