<template>
  <CContainer>
    <CNav variant="tabs">
      <CNavItem
        v-on:click.native="changeTab(tabConversionRates)"
        :active="activeTab === tabConversionRates"
      >{{ $t("ANALYTICS.MENU.CONVERSION_RATE") }}</CNavItem>
      <CNavItem
        v-on:click.native="changeTab(tabTransactions)"
        :active="activeTab === tabTransactions"
      >{{ $t("ANALYTICS.MENU.TRANSACTION") }}</CNavItem>
    </CNav>
    <Transaction v-show="activeTab === tabTransactions" />
    <ConversionRate v-show="activeTab === tabConversionRates" />
  </CContainer>
</template>
<script>
import Transaction from "./Transaction";
import ConversionRate from "./ConversionRate";

export default {
  name: "Settings",
  components: {
    Transaction,
    ConversionRate
  },
  data() {
    return {
      tabConversionRates: "conversion_rate",
      tabTransactions: "transactions",
      activeTab: ""
    };
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    }
  },
  mounted() {
    this.activeTab = this.tabConversionRates;
  }
};
</script>
<style scoped>
.settings-container {
  padding-top: 10px;
}
</style>