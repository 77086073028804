import Chart from '../chart'
export default class ChartBuilder {
    constructor(id = "", dataset = [], labels = [], max = 0, percentage = 0, total = 0, details = {}, model = {}) {
        this.id = id;
        this.dataset = dataset;
        this.labels = labels;
        this.max = max;
        this.percentage = percentage;
        this.total = total;
        this.details = details;
        this.model = model;
    }

    build() {
        return new Chart(this);
    }
    

}