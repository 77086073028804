import moment from 'moment-timezone';
import { i18n } from './../main.js'

export default class Chart {
    constructor(builder) {
        this.id = builder.id;
        this.dataset = builder.dataset;
        this.labels = builder.labels;
        this.max = builder.max;
        this.percentage = builder.percentage;
        this.total = builder.total;
        this.details = builder.details;
        this.model = builder.model;
    }
    futureDates(date) {
        return date > new Date(new Date().setHours(0, 0, 0, 0));
    }
    getLabels(from, to, time_period) {
        let date = new Date(moment(from).format("DD-MMM-YYYY"));
        switch (time_period.value) {
            case "daily":
                return moment(from).format("DD-MMM");
            case "monthly":
                return (
                    this.getMonth(date.getMonth())
                );
            case "weekly":
                return (
                    moment(from).format("DD-MMM") +
                    "-" +
                    moment(to).format("DD-MMM")
                );
            case "yearly":
                return moment(from).format("YYYY");
            default:
                break;
        }
    }
    getMonth(month) {
        switch (month) {
            case 0:
                return 'Jan'
            case 1:
                return 'Feb'
            case 2:
                return 'Mar'
            case 3:
                return 'Apr'
            case 4:
                return 'May'
            case 5:
                return 'Jun'
            case 6:
                return 'July'
            case 7:
                return 'Aug'
            case 8:
                return 'Sep'
            case 9:
                return 'Oct'
            case 10:
                return 'Nov'
            case 11:
                return 'Dec'
        }
    }
    getByKey(data, key) {
        switch (key) {
            case "dataset":
                return data.dataset;
            case "label":
                return data.labels;
            case "max":
                return data.max;
            case "total":
                return data.total;
            default:
                break;
        }
    }
    getDates(val) {
        let today = new Date();
        let before = new Date();
        before.setDate(before.getDate() - val.days);
        this.model.dates = [
            new Date(before.getFullYear(), before.getMonth(), before.getDate()),
            new Date(today.getFullYear(), today.getMonth(), today.getDate())
        ];
    }
    selectDefaultDateRange(val) {
        this.model.selected_default_date_range = val;
        this.model.chartDisplayOptions = val.displayOptions;
        let displayOpts = this.defaultOpt(val.displayOptions);
        this.model.time_period = displayOpts;
        if (val.days > 0) {
            if (this.model.days != val.days) {
                this.model.days = val.days;
                this.getDates(this.model.selected_default_date_range);
            }
        }
    }
    defaultOpt(options) {
        let defaultOpt = {};
        options.forEach(element => {
            if (element.default) {
                defaultOpt = element;
            }
        });
        return defaultOpt;
    }
    getTimeFilters() {
        return [
            { label: i18n.t("COMMON.TIMEFILTERS.TODAY"), days: 1, displayOptions: this.getChartDisplayOptions(1), default: false },
            { label: i18n.t("COMMON.TIMEFILTERS.LAST_7DAYS"), days: 7, displayOptions: this.getChartDisplayOptions(7), default: false },
            { label: i18n.t("COMMON.TIMEFILTERS.LAST_4WEEKS"), days: 28, displayOptions: this.getChartDisplayOptions(28), default: false },
            { label: i18n.t("COMMON.TIMEFILTERS.LAST_30DAYS"), days: 30, displayOptions: this.getChartDisplayOptions(30), default: true },
            { label: i18n.t("COMMON.TIMEFILTERS.LAST_12MONTHS"), days: 365, displayOptions: this.getChartDisplayOptions(365), default: false },
            { label: i18n.t("COMMON.TIMEFILTERS.CUSTOM"), days: 0, displayOptions: this.getChartDisplayOptions(0), default: false }
        ]
    }
    getChartDisplayOptions(days) {
        let daily = { label: i18n.t("COMMON.TIMEFILTERS.DISPLAY.DAILY"), value: "daily", default: true }
        let weekly = { label: i18n.t("COMMON.TIMEFILTERS.DISPLAY.WEEKLY"), value: "weekly", default: false }
        let monthly = { label: i18n.t("COMMON.TIMEFILTERS.DISPLAY.MONTHLY"), value: "monthly", default: false }
        let yearly = { label: i18n.t("COMMON.TIMEFILTERS.DISPLAY.YEARLY"), value: "yearly", default: false }
        switch (days) {
            case 1:
            case 7:
                return [daily]
            case 28:
            case 30:
                return [daily, weekly]
            case 365:
                return [daily, weekly, monthly]
            default:
                return [daily, weekly, monthly, yearly]
        }
    }
    getDetails(id, key) {
        var chartMap = new Map(
            this.model.allCharts.map(obj => [obj.id, obj])
        );
        var item = chartMap.get(id);
        if (item !== undefined) {
            return this.getByKey(item, key);
        }
        return "";
    }
    initialize() {
        this.model.dayOptions = this.getTimeFilters();
        this.model.selected_default_date_range = this.defaultOpt(this.model.dayOptions);
        this.selectDefaultDateRange(this.model.selected_default_date_range);
    }
}