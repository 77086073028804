<template>
  <CChartBar :datasets="defaultDatasets" :labels="labels" :options="defaultOptions" />
</template>

<script>
import { CChartBar } from "@coreui/vue-chartjs";

export default {
  name: "PageToPageConversionRate",
  components: { CChartBar },
  props: ["DataSets", "Labels", "Max", "Total"],
  computed: {
    defaultDatasets() {
      return [
        {
          label: this.$t("ANALYTICS.CONVER_DROP_OFF"),
          backgroundColor: "#418AF8",
          data: this.DataSets
          // barPercentage: 1
        }
      ];
    },
    labels() {
      return this.Labels;
    },
    defaultOptions() {
      return {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            title: function(t, d) {
              return d.labels[t[0].index][0] + " " + d.labels[t[0].index][1];
            },
            label: function(tooltipItems, data) {
              return tooltipItems.yLabel + "%";
            }
          }
        },
        elements: {
          line: {
            tension: 0
          },
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        },
        scales: {
          xAxes: [
            {
              display: true,
              barPercentage: 0.4,
              ticks: {
                fontFamily: "Apercu",
                fontSize: 10,
                fontColor: "#aaa",
                maxRotation: 0,
                callback: function(t) {
                  return t;
                },
                beginAtZero: false,
                maxTicksLimit: 10
              },
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                callback: function(label) {
                  return label + " %";
                },
                maxTicksLimit: 6,
                beginAtZero: false,
                maxTicksLimit: 6,
                fontFamily: "Apercu",
                fontSize: 10,
                fontColor: "#aaa",
                maxRotation: 0
              },
              display: true,
              gridLines: {
                color: "#eee"
              }
            }
          ]
        }
      };
    }
  }
};
</script>
