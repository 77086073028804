import { httpClient } from './httpClient.js';

const TRANSACTIONS = 'dashboard/analytics/transactions',
    CONVERSION_RATE = 'dashboard/analytics/conversions/';


const listTransactionsData = (filterParams) => httpClient.dashboardService.get(TRANSACTIONS, { "params": filterParams });
const getConversionRates = (filterParams) => httpClient.dashboardService.get(CONVERSION_RATE, { "params": filterParams });

export {
    listTransactionsData,
    getConversionRates,
};
