<template>
  <CChartLine
    :datasets="defaultDatasets"
    :options="defaultOptions"
    :labels="labels"
  />
</template>

<script>
import { CChartLine } from "@coreui/vue-chartjs";

export default {
  name: "TransactionsCountChart",
  components: {
    CChartLine,
  },
  props: ["DataSets", "Labels", "Max", "Col", "Name"],
  computed: {
    defaultDatasets() {
      return [
        {
          label: this.Name,
          backgroundColor: "#FFFAE5",
          borderColor: "#FFCE00",
          borderWidth: 2,
          data: this.DataSets,
        },
      ];
    },
    labels() {
      let labels = this.Labels;
      return labels;
    },
    defaultOptions() {
      return {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                maxTicksLimit: 2,
                fontFamily: "Apercu",
                fontSize: 10,
                fontColor: "#aaa",
                maxRotation: 0,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                callback: function (label, index, labels) {
                  if (label > 1000) {
                    return label / 1000 + "k";
                  }
                  return label;
                },
                fontFamily: "Apercu",
                fontSize: 10,
                fontColor: "#aaa",
                beginAtZero: false,
                maxTicksLimit: 5,
              },
              gridLines: {
                color: "#eee",
                display: true,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: function(t, d) {
              return (
                d.labels[t[0].index]
              );
            },
            label: function(tooltipItems, data) {
              return tooltipItems.yLabel;
            }
          }
        },
        elements: {
          line: {
            tension: 0,
          },
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
          },
        },
      };
    },
  },
};
</script>
